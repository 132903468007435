import React, { Component } from "react";
import DatePicker from "react-date-picker";
import { StaticImage } from "gatsby-plugin-image";
import Confetti from "react-confetti";
import Loader from "react-loader-spinner";

// services
import { sendToCT } from "../../services/Clevertap";

// email
import { sendEmailNotifecation } from "../../services/SendEmail";

// utils
import { convertIstDateFormat } from "../../utils";

// api call
import { updateClientDetailsPaymentPage } from "../../actions/bookPackActions";

import * as style from "./payment-status.module.css";

class Payment extends Component {
  constructor() {
    super();
    this.state = {
      fields: {},
      pmtScreen: true,
      error: "",
      showConfetti: true,
      startDate: new Date(),
      loading: false,
      serverError: "",
    };
  }

  componentDidMount() {
    if (window.twinApp) {
      let data = JSON.stringify({ action: "bookpack_payment_success" });
      window.twinApp.postMessage(data);
      //   console.log("Payment completion working on android!");
    } else if (window.webkit) {
      window.webkit.messageHandlers.twinApp.postMessage({
        action: "bookpack_payment_success",
      });
      //   console.log("Payment completion working on ios!");
    } else {
      console.log("Payment completion working on web!");
    }

    //clevertap event
    sendToCT(`Enrollment successful`, {
      action: "Book_Pack Enrollment Successful",
    });

    setTimeout(() => this.setState({ showConfetti: false }), 2000);
  }

  handleOpenMobileApp = () => {
    if (window.twinApp) {
      let data = JSON.stringify({ action: "payment_success" });
      window.twinApp.postMessage(data);
      //   console.log("Payment completion working on android!");
    } else if (window.webkit) {
      window.webkit.messageHandlers.twinApp.postMessage({
        action: "payment_success",
      });
      //   console.log("Payment completion working on ios!");
    } else {
      console.log("Payment completion working on web!");
    }
  };

  handleValidation = () => {
    let formdata = this.state.fields;
    let error = "";
    let formIsValid = true;
    // console.log("form-data", formdata);
    if (!formdata["address"] || !formdata["gender"] || !formdata["meal"]) {
      formIsValid = false;
      error = "Please fill all the data";
    }

    this.setState({ error: error });
    return formIsValid;
  };

  addressDetails = (i, val) => {
    let fields = this.state.fields;
    fields[i] = val;
    this.setState({ fields });
    // console.log("fields", this.state.fields);
  };

  submitDetails = async e => {
    e.preventDefault();

    const { clientId, city, pincode } = this.props;
    const { fields, startDate } = this.state;

    // conversion of date string format into YYYY-MM-DD
    const dateOfBirthVal = convertIstDateFormat(startDate);

    const requestBody = {
      id: clientId,
      address: {
        city: city,
        line1: fields["address"],
        postalCode: pincode,
      },
      vegetarian: fields["meal"] === "veg" ? true : false,
      dateOfBirth: dateOfBirthVal,
      gender: fields["gender"],
    };

    if (this.handleValidation()) {
      try {
        this.setState({ loading: true, serverError: "" });

        // api call to update client details
        await updateClientDetailsPaymentPage(requestBody);

        this.handleOpenMobileApp();
        this.setState({ loading: false, pmtScreen: false });
      } catch (error) {
        // send an email
        sendEmailNotifecation(
          window.location.href,
          requestBody,
          error || {},
          "/marketing/v3/prospect"
        );
        console.error("client api server error", error);
        this.setState({
          loading: false,
          pmtScreen: false,
          serverError: "Try again",
        });
      }
    }
  };

  onChange = value => this.setState({ startDate: value });

  render() {
    const { clientId, amount, name } = this.props;
    const { showConfetti, pmtScreen, fields, startDate, error, loading } =
      this.state;

    return (
      <div className={style.paymentMainDiv}>
        {showConfetti === true ? (
          <Confetti width={"2500px"} height={"200px"} />
        ) : (
          ""
        )}
        {pmtScreen ? (
          <div className="container">
            <div className="d-flex justify-content-center">
              <div className="d-flex">
                <div className="mt-2">
                  <StaticImage
                    src="../../images/payment-status/checkIcon.svg"
                    placeholder="blurred"
                    alt="payment_status"
                  />
                </div>
                <div className={style.paymentSuccContent}>
                  <div className="text-left ml-2">
                    <h6>Successfully paid {amount ? `₹${amount}` : null}</h6>
                    <p>
                      Receipt will be sent to your registered email address.
                    </p>
                  </div>
                </div>
              </div>
            </div>

            {clientId ? (
              <div className="mb-3">
                <div className={style.pmtdetails}>
                  <div className={style.pmtdetailsLeft}>
                    <h2>
                      <StaticImage
                        src="../../images/payment-status/location.svg"
                        className={style.greenlocsvg}
                        placeholder="blurred"
                        alt="payment_status"
                      />
                      {name}
                    </h2>
                    <h6>
                      Please provide further information to complete your
                      registration process
                    </h6>
                    <p>* All input fields are mandatory</p>
                  </div>
                  <div className={style.pmtdetailsRight}>
                    <form onSubmit={this.submitDetails}>
                      <div className="form-group mb-3">
                        <label className="mb-2" htmlFor="address1">
                          Address *
                        </label>
                        <textarea
                          id="address1"
                          type="text"
                          className={`form-control ${style.pmtFormBgInput}`}
                          placeholder="Address"
                          onChange={e =>
                            this.addressDetails("address", e.target.value)
                          }
                          value={fields["address"]}
                        />
                      </div>

                      <div className="form-group mb-3">
                        <label className="mb-2" htmlFor="gender">
                          Gender *
                        </label>
                        <br />
                        <div className={style.formRadioOptions}>
                          <label className={style.cust_radio}>
                            Male
                            <input
                              type="radio"
                              id="male"
                              name="gender"
                              value="MALE"
                              onChange={e =>
                                this.addressDetails("gender", e.target.value)
                              }
                            />
                            <span
                              className={style.cust_radio_checkround}
                            ></span>
                          </label>

                          <label className={style.cust_radio}>
                            Female
                            <input
                              type="radio"
                              id="female"
                              name="gender"
                              value="FEMALE"
                              onChange={e =>
                                this.addressDetails("gender", e.target.value)
                              }
                            />
                            <span
                              className={style.cust_radio_checkround}
                            ></span>
                          </label>
                        </div>
                      </div>
                      <div className="form-group mb-3">
                        <label className="mb-2" htmlFor="meal">
                          Meal Preference *
                        </label>
                        <br />
                        <div className={style.formRadioOptions2}>
                          <label className={style.cust_radio}>
                            Vegetarian
                            <input
                              type="radio"
                              id="veg"
                              name="meal"
                              value="veg"
                              onChange={e =>
                                this.addressDetails("meal", e.target.value)
                              }
                            />
                            <span
                              className={style.cust_radio_checkround}
                            ></span>
                          </label>

                          <label
                            className={`${style.cust_radio} ${style.cust_radio_non_veg}`}
                          >
                            Non-Vegetarian
                            <input
                              type="radio"
                              id="non-veg"
                              name="meal"
                              value="non-veg"
                              onChange={e =>
                                this.addressDetails("meal", e.target.value)
                              }
                            />
                            <span
                              className={style.cust_radio_checkround}
                            ></span>
                          </label>
                        </div>
                      </div>
                      <div className="form-group mb-4">
                        <label htmlFor="date" className="mb-2">
                          Date Of Birth *
                        </label>
                        <br />
                        <div style={{ maxWidth: "500px" }}>
                          <DatePicker
                            value={startDate}
                            // minDate={startDate}
                            format="y-MM-dd"
                            onChange={this.onChange}
                          />
                        </div>
                      </div>
                      <span className="errorclass text-center">{error}</span>
                      <div style={{ textAlign: "center" }}>
                        <button
                          type="submit"
                          className={`${style.pmtSubmit} ${
                            loading ? "btn_event" : ""
                          }`}
                        >
                          <div style={{ position: "relative" }}>
                            <span
                              className={`${loading ? "btn_text_event" : ""}`}
                            >
                              <span className="bookBtn">SUBMIT</span>
                            </span>
                            <span className="btn_loader">
                              <Loader
                                type="ThreeDots"
                                color="#FFF"
                                height={30}
                                width={30}
                                visible={loading}
                              />
                            </span>
                          </div>
                        </button>
                      </div>
                      {/* {serverError && (
                    <span className="errorclass mt-2">{serverError}</span>
                  )} */}
                    </form>
                  </div>
                </div>
              </div>
            ) : null}
          </div>
        ) : (
          <div className="container">
            <div className="d-flex justify-content-center">
              <div className="d-flex">
                <div>
                  <StaticImage
                    src="../../images/payment-status/green-loc.png"
                    placeholder="blurred"
                    className={style.greenlocsvg}
                    alt="payment_status"
                  />
                </div>
                <div className={style.paymentSuccContent}>
                  <div className="text-left">
                    <h6 className="mt-3">
                      Great! Your address details are updated!{" "}
                    </h6>
                    {/* <p>
                      Our lab tests vendor will contact you to fix suitable
                      appointment for the blood draw
                    </p> */}
                  </div>
                </div>
              </div>
            </div>

            <div className={`mb-4 ${style.pmtappdetails}`}>
              <div className={style.pmtappdetailssub}>
                <h2>Next Step</h2>
                <p>
                  Download the Twin app
                  {/* <span>“Schedule your complete blood analysis today”</span> */}
                </p>
                <div className="d-flex justify-content-start">
                  <div>
                    <StaticImage
                      src="../../images/payment-status/phone.png"
                      placeholder="blurred"
                      className={style.pmt_details_img_1}
                      alt="payment_status"
                    />
                  </div>
                  <div className="ml-4 d-flex flex-column">
                    <div>
                      <a
                        target="_blank"
                        rel="noreferrer"
                        href="https://apps.apple.com/in/app/twin-health/id1456423142"
                      >
                        <StaticImage
                          src="../../images/payment-status/app-store.png"
                          placeholder="blurred"
                          alt="payment_status"
                        />
                      </a>
                    </div>

                    <div className="mt-auto">
                      <a
                        target="_blank"
                        rel="noreferrer"
                        href="https://play.google.com/store/apps/details?id=com.twinsdigital.v2"
                      >
                        <StaticImage
                          src="../../images/payment-status/google-play-store.png"
                          placeholder="blurred"
                          alt="payment_status"
                        />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default Payment;
