// extracted by mini-css-extract-plugin
export var checkout_btn = "payment-status-module--checkout_btn--1Wll7";
export var cust_radio = "payment-status-module--cust_radio--t+RYL";
export var cust_radio_checkround = "payment-status-module--cust_radio_checkround--vLYV9";
export var cust_radio_non_veg = "payment-status-module--cust_radio_non_veg--pE9vw";
export var formRadioOptions = "payment-status-module--formRadioOptions--HEe5Q";
export var formRadioOptions2 = "payment-status-module--formRadioOptions2--N6a9R";
export var greenlocsvg = "payment-status-module--greenlocsvg--+dyat";
export var paymentMainDiv = "payment-status-module--paymentMainDiv--1nCIg";
export var paymentSuccContent = "payment-status-module--paymentSuccContent--1OVFP";
export var pmtFormBgInput = "payment-status-module--pmtFormBgInput--hx7FY";
export var pmtSubmit = "payment-status-module--pmtSubmit--bG+v9";
export var pmt_details_img_1 = "payment-status-module--pmt_details_img_1--cgmBU";
export var pmtappdetails = "payment-status-module--pmtappdetails--qrddF";
export var pmtappdetailsimages = "payment-status-module--pmtappdetailsimages--2cjps";
export var pmtappdetailssub = "payment-status-module--pmtappdetailssub--jMqfT";
export var pmtdetails = "payment-status-module--pmtdetails--Daw26";
export var pmtdetailsLeft = "payment-status-module--pmtdetailsLeft--+ZPH2";
export var pmtdetailsRight = "payment-status-module--pmtdetailsRight--+avHN";
export var tick = "payment-status-module--tick--+Kn+l";