import React, { Component } from "react";
import queryString from "query-string";
import AppHeader from "../components/AppHeader/AppHeader";
// import Header from "../components/Header/Header";
import { Spinner } from "react-bootstrap";

// seo
import Seo from "../components/Seo";

// util
import LS from "../utils/LocalStorageService";

// component
import Payment from "../components/PaymentStatus/payment";

// api actions
import { getClientDetails } from "../actions/bookPackActions";

class PaymentStatus extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userDetails: "",
      fetching: false,
      serverError: "",
      appHeader: "",
      clientId: 0,
      loading: false,
      city: "",
      postalCode: "",
    };
  }

  // Function -> To fetch the client details
  getInitialData = async clientId => {
    try {
      // api call -> to get client details
      this.setState({ loading: true });
      const response = await getClientDetails(clientId);
      this.setState({
        userDetails: response.results[0],
        fetching: true,
        clientId: response.results[0].clientId,
        city: response.results[0].city,
        postalCode: response.results[0].postalCode,
        loading: false,
      });
    } catch (error) {
      console.error("client api server error", error);
      this.setState({
        serverError: "Unable to fetch your details. Try again",
        fetching: true,
      });
    }
  };

  componentDidMount() {
    const lsValue = LS.getValue("header");
    if (lsValue) {
      this.setState({ appHeader: lsValue.appHeader });
    }

    const { clientId } = queryString.parse(this.props.location.search);
    if (clientId) {
      this.getInitialData(clientId);
    }
  }

  render() {
    const { userDetails, appHeader, clientId, city, postalCode, loading } =
      this.state;

    return (
      <div>
        <Seo
          title="Diabetes Reversal Program"
          description="1000's have reversed their diabetes using Twin's proven AI based precision treatment."
        />

        {appHeader === "mobile" && (
          <AppHeader text="" isBack={true} />
        )}

        {/* {!appHeader && <Header location={this.props.location} />} */}

        {loading ? (
          <div
            className="d-block text-center"
            style={{ height: "95vh", paddingTop: "70%" }}
          >
            <Spinner animation="border" role="status" />
          </div>
        ) : (
          <>
            <Payment
              clientId={clientId || ""}
              name={userDetails.name || ""}
              city={city || ""}
              pincode={postalCode || ""}
              amount={userDetails.amountPaid || ""}
              appHeader={appHeader}
            />
          </>
        )}
      </div>
    );
  }
}

export default PaymentStatus;
